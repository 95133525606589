import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import theme from '~/styles/theme'
import { boxSystem } from './box'
import { buttonReset } from './button'

const linkStyle = css`
  transition: all 150ms ease-in-out;
  border-bottom: 1px dashed ${props => props.underlined ? 'currentColor' : 'transparent'};

  &:hover {
    color: ${props => theme.colors[props.hoverColor] || props.hoverColor || 'currentColor'};
    border-bottom: 1px dashed ${props => props.underlined ? 'transparent' : 'currentColor'};
  }
`

const linkElement = ({ to }) => {
  if (!to) {
    return 'button'
  }
  if (to.startsWith('/')) {
    return Link
  }
  return 'a'
}

export default styled.a.attrs(props => ({
  as: linkElement(props),
  to: linkElement(props) === 'a' ? null : props.to,
  href: linkElement(props) === 'a' ? props.to : null,
  target: linkElement(props) === 'a' ? props.target : null,
}))`
  ${boxSystem}
  ${buttonReset}
  ${props => props.plain ? '' : linkStyle}
`
