import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
{
  craft {
    footer: globalSet(handle: "footer") {
      ... on Craft_footer_GlobalSet {
        aboutBlock {
          ... on Craft_aboutBlock_BlockType {
            heading
            subheading
            entry {
              uri
            }
          }
        }
        navigationLinks {
          ... on Craft_navigationLinks_BlockType {
            text: linkText
            entry {
              uri
              title
            }
          }
        }
        instagramLink {
          ... on Craft_instagramLink_BlockType {
            text: linkText
            url: linkUrl
          }
        }
      }
    }
  }
}
`

export const useCraft = (mapCraft = craft => craft) => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}