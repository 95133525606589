import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Link
} from '~/styles/components'

import { MenuModal, NavList, NavItem, CloseButton } from './style'

const MobileMenu = ({
  isOpen,
  items,
  onClose,
  location
}) => {
  const isCurrentPath = (path) => {
    const currentPath = location.pathname.replace(/\/$/, '')
    return `/${path}` === currentPath
  }

  return (
    <>
      {isOpen &&
        <Box
          position="fixed"
          left="0"
          right="0"
          top="0"
          bottom="0"
          onClick={onClose}
          zIndex="1"
        />
      }
      <MenuModal open={isOpen}>
        <CloseButton onClick={onClose} />
        <NavList>
          {items.map((item, i) =>
            <NavItem
              key={i}
              typography="headingLarge"
            >
              <Link
                to={`/${item.uri}`}
                underlined={isCurrentPath(item.uri)}
                onClick={onClose}
              >
                {item.text}
              </Link>
            </NavItem>
          )}
        </NavList>
      </MenuModal>
    </>
  )
}

MobileMenu.propTypes = {
  isOpen: PropTypes.boolean,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string,
      text: PropTypes.string
    })
  ),
  onClose: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
}

export default MobileMenu