import styled from 'styled-components'
import { boxSystem } from './box'

export default styled.div.attrs(props => ({ dangerouslySetInnerHTML: { __html: props.html }}))`
  ${boxSystem}

  > p {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`