import styled, { css } from 'styled-components'
import theme from '~/styles/theme'
import { boxSystem } from './box'

const numberOffsets = [
  .125,
  .125,
  .225,
  .25,
  .25,
  .25,
  .1,
  .225,
  .075,
  .25
]

const bulletNumber = css`
  &:after {
    content: '${props => props.number}';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: -${props => numberOffsets[props.number - 1] || .175}em;
  }
`

export default styled.span`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
  color: ${props => theme.colors[props.color] || props.color || 'white'};
  width: ${props => props.large ? 2 : 1.5}em;
  flex-shrink: 0;
  height: 0.75em;
  ${boxSystem}
  background: none;
  z-index: 1;

  &:before {
    content: '';
    background-color: ${props => theme.colors[props.bg] || props.g || 'black'};
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: ${props => props.large ? 2 : 1.5}em;
    height: ${props => props.large ? 2 : 1.5}em;
    border-radius: 50%;
    z-index: -1;
  }

  ${props => !!props.number ? bulletNumber : ''}

  svg {
    width: ${props => props.large ? 1 : .75}em;
    height: ${props => props.large ? 1 : .75}em;
    fill: ${props => theme.colors[props.color] || props.color || 'white'};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
`