import breakpoints from './breakpoints'

export default {
  gridSize: 12,
  gutterWidth: 4,
  outerMargin: 3,
  container: {},
  breakpoints: {
    xs: parseInt(breakpoints.xs),
    sm: parseInt(breakpoints.sm),
    md: parseInt(breakpoints.md),
    lg: parseInt(breakpoints.lg),
    xl: parseInt(breakpoints.xl)
  }
}
