import styled from 'styled-components'
import theme from '~/styles/theme'
import { boxSystem } from './box'
import {
  Row as FlexboxRow,
  Col as FlexboxCol
} from 'react-styled-flexboxgrid'

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-left: ${theme.flexboxgrid.outerMargin}rem;
  padding-right: ${theme.flexboxgrid.outerMargin}rem;
  ${boxSystem}
`

export const Row = styled(FlexboxRow)`
  ${boxSystem}
`
export const Col = styled(FlexboxCol)`
  max-width: 100%;
  ${boxSystem}
`
