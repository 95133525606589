import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
{
  craft {
    menu: globalSet(handle: "menu") {
      ... on Craft_menu_GlobalSet {
        navigationLinks {
          ... on Craft_navigationLinks_BlockType {
            text: linkText
            entry {
              uri
              title
            }
          }
        }
      }
    }
  }
}
`

export const useCraft = (mapCraft = craft => craft) => {
  const { craft } = useStaticQuery(query)
  return mapCraft(craft)
}