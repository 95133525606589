import theme from './theme'

export const typographyStyle = (name) => {
  const { default: base, ...scale } = theme.typography[name]

  const properties = {
    fontFamily: 'font-family',
    fontSize: 'font-size',
    fontWeight: 'font-weight',
    lineHeight: 'line-height',
    textTransform: 'text-transform',
    letterSpacing: 'letter-spacing',
    marginBottom: 'margin-bottom'
  }

  let css = ''
  Object.keys(base).forEach(key => {
    css += `${properties[key]}: ${base[key]}; `
  })
  Object.keys(scale).forEach(bp => {
    css += `@media screen and (min-width: ${theme.breakpoints[bp]}) { `
    Object.keys(scale[bp]).forEach(key => {
      css += `${properties[key]}: ${scale[bp][key]}; `
    })
    css += `}`
  })

  return css
}