import styled from 'styled-components'
import theme from '~/styles/theme'
import { boxSystem } from '~/styles/components/box'

export const CloseButton = styled.button`
  position: absolute;
  top: 2rem;
  left: 0;
  transform: translateX(-50%);
  width: 4rem;
  height: 4rem;
  border: 2px solid black;
  background: white;
  border-radius: 50%;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 1.5rem;
    background: black;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 1px;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

export const NavList = styled.ul`
  margin: 0;
`

export const NavItem = styled.li`
  ${boxSystem}
  padding: 0;
  margin: 3rem 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: none;
  }
`

export const MenuModal = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 32rem;
  max-width: calc(100vw - 4rem);
  background: ${theme.colors.purple};
  color: white;
  z-index: 2;
  padding: 3rem;
  padding-top: 8rem;
  transform: ${props => props.open ? 'none' : 'translateX(100%)'};
  opacity: ${props => props.open ? '1' : '0'};
  transition: all 300ms ease-in-out;
`