import { only } from '~/utils/mapping-filters.js'

const mapFooter = (footer) => {
  const aboutBlock = only(footer.aboutBlock)
  return {
    aboutBlock: {
      heading: aboutBlock.heading,
      subheading: aboutBlock.subheading,
      pageLink: only(aboutBlock.entry).uri || ''
    },
    navigationLinks: footer.navigationLinks.map(link => {
      const entry = only(link.entry)
      return {
        text: link.text || entry.title || '',
        uri: entry.uri || ''
      }
    }),
    instagramLink: only(footer.instagramLink)
  }
}

export const mapCraft = (craft) => {
  return {
    footer: mapFooter(craft.footer)
  }
}