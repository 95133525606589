import styled from 'styled-components'
import theme from '~/styles/theme'
import { boxSystem } from './box'

export default styled.svg.attrs(props => ({ as: theme.icons[props.name] }))`
  fill: currentColor;
  height: 1em;
  width: 1em;
  ${boxSystem}
`
