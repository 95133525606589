import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'

import { useStore, StoreContext } from '~/store'

import Header from '~/components/Header'

import '~/styles/fonts.scss'
import GlobalStyle from '~/styles/global'
import theme from '~/styles/theme'
import { Box } from '~/styles/components'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
`

const QuizLayout = ({ children, location }) => {
  const store = useStore()

  return (
    <ThemeProvider theme={theme}>
      <StoreContext.Provider value={store}>
        <>
          <GlobalStyle />
          <Wrapper>
            <Header location={location} />
            <Box
              as="main"
              flex="1 1"
              position="relative"
              bg="cream"
              overflowY="auto"
              zIndex="1"
            >
              {children}
            </Box>
          </Wrapper>
        </>
      </StoreContext.Provider>
    </ThemeProvider>
  )
}

QuizLayout.propTypes = {
  children: PropTypes.array,
  location: PropTypes.object
}

export default QuizLayout
