import styled, { css } from 'styled-components'
import theme from '~/styles/theme'
import { typographyStyle } from '../utils'
import { boxSystem } from './box'

export const Paragraph = styled.p`
  ${boxSystem}
  ${({ large }) => typographyStyle(large ? 'paragraphLarge' : 'paragraph')}
`

export const Heading = styled.h5.attrs(({ level }) => ({ as: `h${level || 5}` }))`
  ${boxSystem}
`

const highlightImage = (color) => {
  const fill = escape(theme.colors[color] || color)
  return `
    <svg preserveAspectRatio="none" viewBox="0 0 470 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M148.767 0.411542C117.324 0.0329784 81.2499 -0.345585 50.1319 0.557143C35.9947 0.964826 28.7568 -0.31351 19.657 1.66667C8.44476 4.12733 2.80591 6.11129 1.34344 9.27083C-1.41899 15.1677 0.855882 31.1406 1.34337 36.9792C2.48084 49.4135 1.05801 54.9974 3.90169 67.4318C4.55168 70.2419 6.01414 73.3723 17.7139 75.2506C26.7324 76.7066 39.6509 77.0123 51.7568 77.2162C112.043 78.2208 172.491 78.9052 233.021 79.2837C290.382 79.6477 352.781 80.8708 409.33 78.9343C414.692 78.745 419.731 78.7173 425.752 78.9343C435.093 79.2708 441.735 80.07 447.341 78.9343C467.247 74.9594 467.584 70.0815 469.697 65C469.697 56.3963 469.291 50.1052 469.697 44.3831C470.509 32.8078 469.697 22.9167 467.156 9.16668C466.344 7.87083 458.566 7.08008 454.341 6.01719C447.029 4.19717 432.81 3.87685 420.217 3.73125C329.788 2.62468 239.277 1.51811 148.767 0.411542Z"
        fill="${fill}"
      />
    </svg>  
  `
}

export const highlightStyle = (color) => css`
  position: relative;
  display: inline;
  z-index: 1;
  white-space: nowrap;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: .35em;
    left: -.15em;
    right: -.15em;
    height: 1.15em;
    background-image: url('data:image/svg+xml;utf8,${highlightImage(color)}');
    background-size: 100% 75%;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:after {
    content: "\\A";
    white-space: pre;
  }
`

export const Highlight = styled.span`
  ${props => highlightStyle(props.color)}
`
