import { only } from '~/utils/mapping-filters.js'

const mapMenu = (menu) => {
  return {
    navigationLinks: menu.navigationLinks.map(link => {
      const entry = only(link.entry)
      return {
        text: link.text || entry.title || '',
        uri: entry.uri || ''
      }
    })
  }
}

export const mapCraft = (craft) => {
  return {
    menu: mapMenu(craft.menu)
  }
}