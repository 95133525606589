import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import Header from '~/components/Header'
import Footer from '~/components/Footer'

import '~/styles/fonts.scss'
import GlobalStyle from '~/styles/global'
import theme from '~/styles/theme'

const DefaultLayout = ({ children, location }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Header location={location} />
        <main>{children}</main>
        <Footer />
      </>
    </ThemeProvider>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.array,
  location: PropTypes.object
}

export default DefaultLayout
