// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-big-moments-archive-index-js": () => import("./../../../src/templates/big-moments-archive/index.js" /* webpackChunkName: "component---src-templates-big-moments-archive-index-js" */),
  "component---src-templates-big-moments-index-js": () => import("./../../../src/templates/big-moments/index.js" /* webpackChunkName: "component---src-templates-big-moments-index-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-quiz-index-js": () => import("./../../../src/templates/quiz/index.js" /* webpackChunkName: "component---src-templates-quiz-index-js" */),
  "component---src-templates-quiz-result-index-js": () => import("./../../../src/templates/quiz-result/index.js" /* webpackChunkName: "component---src-templates-quiz-result-index-js" */)
}

