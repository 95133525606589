import styled, { css } from 'styled-components'
import { color } from 'styled-system'
import { Link } from 'gatsby'
import theme from '~/styles/theme'
import { typographyStyle } from '~/styles/utils'
import { boxSystem } from './box'

export const buttonReset = css`
  margin: 0;
  padding: 0;
  overflow: visible;
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  font: inherit;
  appearance: none;
  text-align: left;
  cursor: pointer;
`

const defaultButtonStyle = css`
  ${props => typographyStyle(props.large ? 'headingSmall' : 'headingExtraSmall')}
  ${color}
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2.25rem 1.75rem 2.25rem;
  border-radius: 4px;
  width: ${props => props.wide ? '100%' : 'auto'};
  margin: 0;
  transition: all 150ms ease-in-out;

  svg {
    margin-bottom: -2px;
  }

  &:hover {
    opacity: 0.85;
  }
`

const shadowButtonStyle = css`
  ${defaultButtonStyle}
  background: white;
  color: black;
  border: 2px solid black;
  transition: all 150ms ease-in-out;
  transition-property: background, color;
  position: relative;

  &:after {
    content: '';
    background: black;
    position: absolute;
    top: -2px;
    left: -2px;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: 2px solid black;
    border-radius: 4px;
    transform: translate(8px, 8px);
    transition: transform 150ms ease-in-out;
  }

  &:hover {
    border: 2px solid black; /* Reset global anchor hover */
    background: black;
    color: white;
    opacity: 1;
    
    &:after {
      transform: none;
    }
  }
`

const outlineButtonStyle = css`
  ${defaultButtonStyle}
  border: 1px dashed currentColor;
  background: transparent;
  position: relative;
  border-radius: 2px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => theme.colors[props.color] || props.color || 'currentColor'};
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &:hover {
    border: 1px dashed currentColor; /* Reset global anchor hover */
    opacity: 1;

    &:before {
      opacity: 0.1;
    }
  }
`

const buttonStyle = ({ shadow, outline }) => {
  if (shadow) {
    return shadowButtonStyle
  }
  if (outline) {
    return outlineButtonStyle
  }
  return defaultButtonStyle
}

const buttonElement = ({ to }) => {
  if (!to) {
    return 'button'
  }
  if (to.startsWith('/')) {
    return Link
  }
  return 'a'
}

export default styled.button.attrs(props => ({
  as: buttonElement(props)
}))`
  ${boxSystem}
  ${buttonReset}
  ${props => props.plain ? '' : buttonStyle}
`
