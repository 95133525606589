import space from './space'
import colors from './colors'
import typography, { fonts } from './typography'
import breakpoints from './breakpoints'
import flexboxgrid from './flexboxgrid'
import icons from './icons'

export default {
  space,
  sizes: space,
  colors,
  typography,
  fonts,
  breakpoints,
  flexboxgrid,
  icons
}
