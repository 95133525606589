import ArrowIcon from '~/assets/images/arrow.inline.svg'
import CaretIcon from '~/assets/images/caret.inline.svg'
import CrossIcon from '~/assets/images/cross.inline.svg'
import QuoteIcon from '~/assets/images/quote.inline.svg'
import LinkIcon from '~/assets/images/link.inline.svg'
import HamburgerIcon from '~/assets/images/hamburger.inline.svg'
import InstagramIcon from '~/assets/images/instagram.inline.svg'
import TwitterIcon from '~/assets/images/twitter.inline.svg'
import FacebookIcon from '~/assets/images/facebook.inline.svg'
import CouchIcon from '~/assets/images/big-moments/couch.inline.svg'
import AirplaneIcon from '~/assets/images/big-moments/airplane.inline.svg'
import ConversationIcon from '~/assets/images/big-moments/conversation.inline.svg'
import LendingIcon from '~/assets/images/big-moments/lending.inline.svg'
import SavingIcon from '~/assets/images/big-moments/saving.inline.svg'
import MarriageIcon from '~/assets/images/big-moments/marriage.inline.svg'
import PetIcon from '~/assets/images/big-moments/pet.inline.svg'

export default {
  arrow: ArrowIcon,
  caret: CaretIcon,
  cross: CrossIcon,
  quote: QuoteIcon,
  link: LinkIcon,
  hamburger: HamburgerIcon,
  instagram: InstagramIcon,
  twitter: TwitterIcon,
  facebook: FacebookIcon,
  couch: CouchIcon,
  airplane: AirplaneIcon,
  conversation: ConversationIcon,
  lending: LendingIcon,
  saving: SavingIcon,
  marriage: MarriageIcon,
  pet: PetIcon
}
