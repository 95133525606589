import styled from 'styled-components'
import { boxSystem } from '~/styles/components/box'
import loveCharacter from '~/assets/images/love-character.png'
import powerCharacter from '~/assets/images/power-character.png'
import freedomCharacter from '~/assets/images/freedom-character.png'
import securityCharacter from '~/assets/images/security-character.png'

const characters = {
  love: loveCharacter,
  power: powerCharacter,
  freedom: freedomCharacter,
  security: securityCharacter
}

export default styled.div`
  position: absolute;
  background-image: url(${props => characters[props.name]});
  background-size: contain;
  background-repeat: no-repeat;
  ${boxSystem}
`
