import styled from 'styled-components'
import theme from '~/styles/theme'
import { buttonReset } from '~/styles/components/button'
import LogoSVG from '~/assets/images/logo.inline.svg'

export const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 100;
  height: 7.5rem;
  background: white;
  border-bottom: 1px dashed rgba(0,0,0,0.5);

  @media screen and (min-width: ${theme.breakpoints.lg}) {
    border-bottom: 0;
    height: 6rem;
  }
`

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  height: 100%;
`

export const NavItem = styled.li`
  padding: 0;
  margin: 0 1rem;
  margin-bottom: 5px;
  font-family: ${theme.fonts.heading};
  font-size: 16px;
  font-weight: 300;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:before {
    content: none;
  }
`

export const MenuButton = styled.button`
  ${buttonReset}
  background-color: ${theme.colors.cream};
  border-radius: 50%;
  height: 4.5rem;
  width: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1.5rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const Logo = styled(LogoSVG)`
  width: 20rem;
  margin-left: -1.5rem;
  margin-bottom: -0.75rem;
`
