import { createGlobalStyle } from 'styled-components'

import theme from './theme'
import { typographyStyle } from './utils'
import { highlightStyle } from './components/typography'

export default createGlobalStyle`
  html {
    ${typographyStyle('html')}
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: -0.01em;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    ${typographyStyle('headingExtraLarge')}
  }

  h2 {
    ${typographyStyle('headingLarge')}
  }

  h3 {
    ${typographyStyle('headingMedium')}
  }

  h4 {
    ${typographyStyle('headingSmall')}
  }

  h5,
  h6 {
    ${typographyStyle('headingExtraSmall')}
  }

  p {
    ${typographyStyle('paragraph')}

    &.large {
      ${typographyStyle('paragraphLarge')}
    }
  }

  ul,
  ol {
    margin-top: 3rem;

    li > ul,
    li > ol {
      margin-top: 1.5rem;
    }
  }

  a {
    color: ${theme.colors.purple};
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px dashed currentColor;
    transition: border 150ms ease-in-out;

    &:hover {
      border-bottom-color: transparent;
    }
  }

  u {
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }

  i,
  em {
    font-weight: 300;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 1.8em;
      margin-bottom: 1.5rem;

      &:before {
        content: '→';
        left: 0;
        margin-right: .75rem;
        position: absolute;
        color: ${theme.colors.purple};
      }
    }
  }

  q {
    quotes: '“' '”';
  }

  figure {
    margin: 0;
    padding: 0;
  }

  img {
    display: block;
  }

  .highlight--green {
    ${highlightStyle('green')}
  }
  .highlight--yellow {
    ${highlightStyle('yellow')}
  }
  .highlight--orange {
    ${highlightStyle('orange')}
  }
  .highlight--purple {
    ${highlightStyle('purple')}
  }
  .highlight--pink {
    ${highlightStyle('pink')}
  }

`
