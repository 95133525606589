export default {
  black: '#151414',
  white: '#FFFFFF',
  cream: '#F8EDDB',
  green: '#59BC9B',
  yellow: '#F6D436',
  orange: '#E8740B',
  purple: '#6F69FF',
  pink: '#F19CB2',
  grey: '#E6E6E8'
}
