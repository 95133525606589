import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import MobileMenu from '~/components/MobileMenu'

import {
  Header as StyledHeader,
  NavList,
  NavItem,
  MenuButton,
  Logo
} from './style'
import {
  Box,
  Link,
  Container,
  Icon
} from '~/styles/components'

import { useCraft } from './query'
import { mapCraft } from './mappings'

const Header = ({ location }) => {
  const craft = useCraft(mapCraft)
  const { navigationLinks } = craft.menu

  const mobileMenuRef = useRef(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const openMenu = () => {
    setMenuOpen(true)
    document.documentElement.style.overflow = 'hidden'
    disableBodyScroll(mobileMenuRef.current)
  }

  const closeMenu = () => {
    setMenuOpen(false)
    document.documentElement.style = ''
    enableBodyScroll(mobileMenuRef.current)
  }

  const isCurrentPath = (path) => {
    const currentPath = location.pathname.replace(/\/$/, '')
    return `/${path}` === currentPath
  }

  return (
    <StyledHeader>
      <Container height="100%">
        <Box
          as="nav"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Link plain to="/">
            <Logo />
          </Link>
          <Box display={{ _: 'none', lg: 'block' }}>
            <NavList>
              {navigationLinks.map((link, i) =>
                <NavItem key={i}>
                  <Link
                    to={`/${link.uri}`}
                    hoverColor="purple"
                    underlined={isCurrentPath(link.uri)}
                  >
                    {link.text}
                  </Link>
                </NavItem>
              )}
            </NavList>
          </Box>
          <Box display={{ lg: 'none' }}>
            <MenuButton onClick={openMenu}>
              <Icon name="hamburger" />
            </MenuButton>
            <MobileMenu
              isOpen={menuOpen}
              items={navigationLinks}
              onClose={closeMenu}
              location={location}
            />
          </Box>
        </Box>
      </Container>
    </StyledHeader>
  )
}

Header.propTypes = {
  location: PropTypes.object.isRequired
}

export default Header