const space = ['0', '0.5rem', '1rem', '2rem', '4rem', '8rem', '16rem', '32rem']

space.xxs = space[1]
space.xs = space[2]
space.sm = space[3]
space.md = space[4]
space.lg = space[5]
space.xl = space[6]
space.xxl = space[7]

export default space
