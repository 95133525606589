import React from 'react'

import rmitLogo from '~/assets/images/rmit-logo.png'
import ylabLogo from '~/assets/images/ylab-logo.png'
import ecstraLogo from '~/assets/images/ecstra-logo.png'
import todayLogo from '~/assets/images/today-logo.svg'

import {
  Box,
  Heading,
  Paragraph,
  Bullet,
  Container,
  Row,
  Col,
  Spacer,
  Divider,
  Link,
  Button,
  Icon,
  WavePattern
} from '~/styles/components'
import {
  Footer as StyledFooter,
  NavList,
  NavItem,
  SponsorImage
} from './style'

import { useCraft } from './query'
import { mapCraft } from './mappings'

const Footer = () => {
  const craft = useCraft(mapCraft)
  const {
    navigationLinks,
    aboutBlock,
    instagramLink
  } = craft.footer

  return (
    <StyledFooter>
      <WavePattern
        color="yellow"
        size="xs"
      />
      <Box
        bg="yellow"
        color="black"
      >
        <Spacer size="lg" />
        <Container>
          <Row>
            <Col lg={6}>
              <Heading level="3">
                {aboutBlock.heading}
              </Heading>
              <Spacer size="0" />
            </Col>
            <Col lg={6}>
              <Paragraph large>
                {aboutBlock.subheading}
              </Paragraph>
              <Spacer size="xs" />
              <Row>
                <Col xs={12} lg={10}>
                  <Button
                    outline
                    wide
                    to={`/${aboutBlock.pageLink}`}
                  >
                    Read more about the research
                    <Icon name="arrow" />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Spacer size="lg" />
      </Box>
      <WavePattern
        color="yellow"
        size="xs"
        bottom
      />
      <Spacer size="xs" />
      <Spacer size="md" />
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <Box typography="headingExtraSmall">
              <NavList>
                {navigationLinks.map((link, i) =>
                  <NavItem key={i}>
                    <Link to={`/${link.uri}`} hoverColor="purple">
                      {link.text}
                    </Link>
                  </NavItem>
                )}
              </NavList>
            </Box>
            <Spacer size="sm" />
            <Box typography="headingExtraSmall">
              <Bullet
                bg="yellow"
                color="black"
                icon
              >
                <Icon name="instagram" />
              </Bullet>
              <Link to={instagramLink.url} hoverColor="purple">
                {instagramLink.text}
              </Link>
            </Box>
            <Spacer size={{ _: 'md', lg: 0 }} />
            <Divider display={{ _: 'block', lg: 'none' }} />
          </Col>
          <Col xs={12} lg={6}>
            <Spacer size={{ _: 'md', lg: 0 }} />
            <Box>
              <Heading level="4">
                <i>Supported by</i>
              </Heading>
              <Spacer size="xs" />
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
              >
                <Link plain to="https://sites.rmit.edu.au/cwrg/">
                  <SponsorImage
                    src={rmitLogo}
                    alt="RMIT logo"
                  />
                </Link>
                <Link plain to="https://www.ylab.global/">
                  <SponsorImage
                    src={ylabLogo}
                    alt="YLab logo"
                  />
                </Link>
                <Link plain to="https://www.ecstra.org.au/">
                  <SponsorImage
                    src={ecstraLogo}
                    alt="Ecstra logo"
                  />
                </Link>
                <Link plain to="https://today.design/">
                  <SponsorImage
                    src={todayLogo}
                    alt="Today logo"
                  />
                </Link>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>
      <Spacer size="md" />
    </StyledFooter>
  )
}

export default Footer
