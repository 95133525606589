import { useState, useEffect } from 'react'

import StoreContext, { defaultStoreContext } from './StoreContext'

const useStore = () => {
  const [store, setStore] = useState(defaultStoreContext)

  const initStore = () => {
    const isBrowser = typeof window !== 'undefined'
    const existingStore = isBrowser
      ? localStorage.getItem('quiz_store')
      : null

    if (!!existingStore) {
      setStore({ ...JSON.parse(existingStore), loading: false })
    } else {
      localStorage.setItem('quiz_store', JSON.stringify(store))
      setStore({ ...store, loading: false })
    }
  }

  const resetStore = () => {
    setStore(defaultStoreContext)
    localStorage.removeItem('quiz_store')
  }

  const updateStore = (newStore) => {
    setStore(newStore)
    localStorage.setItem('quiz_store', JSON.stringify(newStore))
  }

  const updateStarted = (started) => updateStore({ ...store, started })
  const updateProgress = (progress) => updateStore({ ...store, progress })
  const updateAnswer = (key, value) => updateStore({ ...store, answers: { ...store.answers, [key]: value } })

  useEffect(() => initStore(), [])

  return {
    ...store,
    updateStarted,
    updateProgress,
    updateAnswer,
    resetStore
  }
}

export { useStore, StoreContext }