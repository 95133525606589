import { createContext } from 'react'

export const defaultStoreContext = {
  loading: true,
  started: false,
  progress: 0,
  answers: {
    age: 18,
    gender: null,
    relationshipStatus: null,
    power1: 2,
    security1: 2,
    freedom1: 2,
    love1: 2,
    power2: 2,
    security2: 2,
    love2: 2,
    freedom2: 2
  }
}

export default createContext(defaultStoreContext)
