export const fonts = {
  paragraph: `"IBM Plex Sans", Helvetica, Arial, sans-serif`,
  paragraphLarge: `"IBM Plex Serif", Georgia, Times New Roman, serif`,
  heading: `"alda", Georgia, Times New Roman, serif`
}

export default {
  html: {
    default: {
      fontFamily: fonts.paragraph,
      fontSize: '10px'
    },
    sm: {
      fontSize: '12px'
    }
  },
  paragraph: {
    default: {
      fontFamily: fonts.paragraph,
      fontSize: '15px',
      fontWeight: '300',
      lineHeight: '150%',
      marginBottom: '2rem'
    },
    sm: {
      fontSize: '18px'
    }
  },
  paragraphLarge: {
    default: {
      fontFamily: fonts.paragraphLarge,
      fontSize: '18px',
      fontWeight: '300',
      lineHeight: '150%',
      marginBottom: '1.5rem'
    },
    sm: {
      fontSize: '24px'
    },
    lg: {
      fontSize: '26px'
    }
  },
  headingExtraSmall: {
    default: {
      fontFamily: fonts.heading,
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '115%',
      marginBottom: '1rem'
    },
    sm: {
      fontSize: '20px'
    }
  },
  headingSmall: {
    default: {
      fontFamily: fonts.heading,
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '115%',
      marginBottom: '1rem'
    },
    sm: {
      fontSize: '24px'
    },
    lg: {
      fontSize: '26px'
    }
  },
  headingMedium: {
    default: {
      fontFamily: fonts.heading,
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '115%',
      marginBottom: '1.5rem'
    },
    sm: {
      fontSize: '28px'
    },
    lg: {
      fontSize: '36px'
    }
  },
  headingLarge: {
    default: {
      fontFamily: fonts.heading,
      fontSize: '28px',
      fontWeight: '700',
      lineHeight: '105%',
      marginBottom: '2rem'
    },
    sm: {
      fontSize: '36px'
    },
    lg: {
      fontSize: '50px'
    }
  },
  headingExtraLarge: {
    default: {
      fontFamily: fonts.heading,
      fontSize: '44px',
      fontWeight: '700',
      lineHeight: '95%',
      marginBottom: '2rem'
    },
    sm: {
      fontSize: '72px',
      marginBottom: '3rem'
    },
    lg: {
      fontSize: '96px'
    }
  },
  headingNumber: {
    default: {
      fontFamily: fonts.heading,
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '100%'
    },
    sm: {
      fontSize: '28px'
    },
    lg: {
      fontSize: '36px'
    }
  }
}
