import React from 'react'
import styled from 'styled-components'
import theme from '~/styles/theme'
import { Box } from '~/styles/components'

const waveImage = (color) => {
  const fill = escape(theme.colors[color] || color)
  return `
    <svg width="60" height="20" viewBox="0 0 60 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30 0C12.5 0 12 20 0 20H60C48 20 47 0 30 0Z"
        fill="${fill}"
      />
    </svg>
  `
}

export const WavePattern = styled.div.attrs(props => ({
  size: props.size || '2'
}))`
  position: relative;
  height: 0;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    top: ${props => props.bottom ? '0' : 'auto'};
    bottom: ${props => props.bottom ? 'auto' : '0'};
    height: ${props => theme.sizes[props.size]};
    transform: ${props => props.bottom ? 'rotate(180deg)' : 'none'};
    background-image: url('data:image/svg+xml;utf8,${props => waveImage(props.color)}');
    background-size: contain;
    background-position: center;
  }
`

const DripPatternWrapper = styled(Box)`
  height: 100%;
  position: absolute;
  top: ${props => props.top};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  @media screen and (min-width: ${theme.breakpoints.sm}) {
    top: 500px;
  }

  ${WavePattern} {
    transform: skew(0, -10deg);
    transform-origin: bottom;

    &:before {
      box-shadow: 0 1000px 0 999px ${props => theme.colors[props.color] || props.color};
      height: 50px;

      @media screen and (min-width: ${theme.breakpoints.sm}) {
        height: 100px;
      }

      @media screen and (min-width: ${theme.breakpoints.lg}) {
        height: 150px;
      }
    }
  }
`

export const DripPattern = ({ top = '500px', color }) => {
  return (
    <DripPatternWrapper top={top} color={color}>
      <Box bg={color} />
      <WavePattern bottom color={color} />
    </DripPatternWrapper>
  )
}
