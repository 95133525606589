import styled from 'styled-components'

export const Footer = styled.footer`
  position: relative;
`

export const NavList = styled.ul`
  margin: 0;
`

export const NavItem = styled.li`
  padding: 0;
  margin-bottom: .75rem;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: none;
  }
`

export const SponsorImage = styled.img`
  width: 80px;
  margin-right: 2rem;
  margin-bottom: 2rem;
`